<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 15:20:37
 * @ Description: Component displaying the public individual profile education tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Education -->
                <v-col
                    v-if="educations === null || educations.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No education added
                </v-col>
                <!-- No Education -->

                <!-- Education -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in educations"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="152px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="12"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiSchoolOutline }}
                                </v-icon>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0 text-truncate"
                                cols="12"
                            >
                                {{ item.course_name }}
                                <br />
                                <span class="os-14-r">({{ item.qualification.name }})</span>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                class="os-12-sb flex-shrink-1 flex-grow-0"
                                style="color: #2E567A"
                            >
                                {{ item.completion_year }}
                            </v-col>
                            <v-col
                                class="os-10-r text-right flex-grow-1 flex-shrink-0 text-truncate"
                                style="color: #8F9BB3;"
                                align-self="center"
                            >
                                {{ item.institution_name }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Education -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Education
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                                v-model="search"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- No Education -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="educationTableHeaders"
                        :items="educations"
                        item-key="id"
                        hide-default-footer
                        no-data-text="No education added"
                        :no-results-text="noResultsText"
                        :search="search"
                    >
                        <template v-slot:[`item.qualification`]="{ item }">
                            {{ item.qualification.name }}
                        </template>
                        <template v-slot:[`item.institution_name`]="{ item }">
                            {{ item.institution_name }}
                        </template>
                        <template v-slot:[`item.reference_number`]="{ item }">
                            {{ item.reference_number }}
                        </template>
                        <template v-slot:[`item.course_name`]="{ item }">
                            {{ item.course_name }}
                        </template>
                        <template v-slot:[`item.language`]="{ item }">
                            {{ item.language.name }}
                        </template>
                        <template v-slot:[`item.completion_year`]="{ item }">
                            {{ item.completion_year }}
                        </template>
                    </v-data-table>
                </v-col>
                <!-- No Education -->
            </v-row>
            <!-- List View -->
        </v-col>
    </v-row>
</template>
<script>
    import { IndividualProfileEducationController } from '@/logic/controller/index.controller.js'
    import { mdiMagnify, mdiSchoolOutline } from '@mdi/js'

    export default {
        name: 'ProfilePublicIndividualEducationTabComponent.vue',

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            }
        },

        data () {
            return {
                mdiMagnify,
                mdiSchoolOutline,
                educationTableHeaders: [
                    {
                        text: 'Qualification',
                        value: 'qualification.name'
                    },
                    {
                        text: 'Course',
                        value: 'course_name'
                    },
                    {
                        text: 'Institution',
                        value: 'institution_name'
                    },
                    {
                        text: 'Reference',
                        value: 'reference_number'
                    },
                    {
                        text: 'Language Skills',
                        value: 'language.name'
                    },
                    {
                        text: 'Year of Completion',
                        value: 'completion_year'
                    }
                ],
                educations: [],
                individualProfileEducationController: null,
                search: ''
            }
        },

        beforeMount () {
            this.individualProfileEducationController = new IndividualProfileEducationController()
        },

        async mounted () {
            await this.getProfileEducation()
        },

        methods: {
            async getProfileEducation () {
                try {
                    this.educations = await this.individualProfileEducationController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
